nav.navbar, .navbar-default {
  background-color: #293a4a !important;
}

.navbar-default {
  background-image: none;
  border:none;
  border-radius:0;
}

.navbar-defalut .navbar-nav > li > a {
    color: #ff6c2c;
    background-color: #333;
}


.navbar-defalut .navbar-brand  {
    color: #ff6c2c;
}

.navbar-defalut .navbar-toggle  {
    color: #ff6c2c;
}

.navbar-defalut .navbar-toggle:focus, .navbar-default  .navbar-toggle:hover {
background-color: transparent ;
}

.navbar-defalut .navbar-brand:focus, .navbar-default  .navbar-brand:hover {
background-color: transparent ;
}

.navbar-defalut .navbar-nav>li>a:focus, .navbar-default  .navbar-nav>li>a:hover {
background-color: transparent ;
color:#ff6c2c;
border : 1px solid #ff6c2c;
transform: scale(1,1);
}

.navbar-default .navbar-nav>li> a {
    color: #ff6c2c;
}
.navbar-default .navbar-nav>li> a.active {
    color: #ff6c2c;
}

a {
    color: #e8e8e8;
    text-decoration: none;
}

