.header-image {
  width:100%;
  margin-top: -2em;
  margin-bottom:  2em;
}

.about-profile-pic {
  float:left;
  margin : 0 1em 1em 0;
}

.aboutFa {
  color: #ff6c2c;
  font-size: 35px;
  text-align: center
}

.aboutSearch {
  color: #ff6c2c;
  font-size: 25px;
  text-align: center
}