body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
   color: #ff6c2c;
   word-wrap: break-word;
}

h3::first-letter {
  font-size: 150%;
  color: #ff6c2c;
}

h1  {
  text-align: center;
  word-wrap: break-word;
}

p {
  color: #293a4a;
  font-weight: 300;
  word-wrap: break-word;
  overflow-wrap: break-word;
}


.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 0px;
  word-wrap: break-word;
}

