.carousel .item {
  height: 500px;
}

.item img {
    position: absolute;
    object-fit:cover;
    top: 0;
    left: 0;
    min-height: 500px;
}

.ClientSlider > div {
  background-color: #5c2e91;
  background-color: #fff;
  color: #fff;
  color: #3498db;
  text-align: center;
  border: 1px solid red;
}



/* backImage {
  background-image: url('./../images/bk11.jpeg');
} */

