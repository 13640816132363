.carousels{
  background: #2f4357; 
  

}
.carousels .item{
    height: 300px; /* Prevent carousel from being distorted if for some reason image doesn't load */
    text-align: center;
}

.testimonialsText {
    text-align: center;
    font-size: 25px;
    font-family: initial;

}

.feedback {
    color:#fff;
    font-size: 20px;
    align-items: flex-end;
    text-align: end;
    font-family: fantasy
}

