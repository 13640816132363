
.Jumbotron {
  margin-bottom: 4em;
}

.header-image {
  width:100%;
  margin-top: -2em;
  margin-bottom:  2em;
}

.person-wrapper {
  margin-bottom: 2em;
}

.btn-primary {
  background-image: none;
  border: none;
  border-radius :0;
  background-color: '#2892D76'
}

h3 {
  margin-bottom: 1em;
}
.industry {
  color: '#293a4a';
  font-size:15;
  font-weight: bold;
  font-family: sans-serif;
}

.borderSpace {
  border-color: #ff6c2c;
  border-width: thick;
  margin: 10px;
}
.pWhiite {
  color:white;
  font-size:17px;
  font-weight: 500;
  font-family: 'Times New Roman', Times, serif
}

.industryExp  {
  background-color: #D4D8D8;
  padding-bottom:40px;
  opacity: 1;
}

.space {
  padding: 120,
}

.overlayApp {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.overlayApp {
  opacity: 1.0;
  filter: alpha(opacity=100); /* For IE8 and earlier */
}

.bgColor {
  background-image: url('./../images/bk11.jpeg');
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.imageWidth {
  width: 100%;
}

.bgColors {
  background-color:rgb(248, 248, 248);
  margin-top: auto;
  margin-bottom: auto;
}

.bgColorTop {
  margin-top: auto;
  margin-bottom: 8%;
}

.bgClients {
  background-image: url('./../images/bk11.jpeg');
  width: auto;
}

.paddingBottom {
  padding-bottom: 80px;
}

.imageStyle {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  border : 1px solid #ff6c2c;
}
.imgMarginTop {
  margin-top: 53px;
}

.space10 {
  margin : 20px;
}
.headlines {
  color:#ff6c2c;
  font-size:31px;
  text-align : center;
  padding-top:10px;
}

.spaceGap {
  margin-top: 60px;
  margin-bottom: 60px
}

.workImages {
  transition: transform 1s; /* Animation */
}
.workImages:hover {
  transform: scale(1.1);
}

.images {
  opacity: 1;
  filter: alpha(opacity=50);
}

.imageGrayscale {

  filter: grayscale(1);
  -webkit-filter: drop-shadow(16px 16px 10px );
}

.images:hover {
  opacity: 0.5;
  filter: alpha(opacity=100);
}


.backImages{
  background-image: url('./../images/bk11.jpeg');
  width: 100%;
  height: auto;
  opacity: 1;
}

.footers{
  background-color:#293a4a;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 40px;
}

.social-links{
  flex-direction: row;
  justify-content: space-between
}

.fa {
    padding: 5px;
    font-size : 24px;
    /* width : 80px; */
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
  }
 
  .socilIcons {
    padding: 5px;
    font-size : 24px;
    width : 35px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
  }

  .socilIcons:hover {
      opacity: 0.7;
      background-color: #dd4b39
  }

  .fa-facebook {
    background: #3B5998;
    color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}
.rowColor {
  border: 1px solid #293a4a;
  width: 10%;
  text-align: 'center'
}
.rowColors {
  border: 1px solid#dd4b39;
  width: 10%;
  text-align: 'center'
}

.rowColor30 {
  border: 1px solid#dd4b39;
  width: 30%;
  text-align: 'center'
}


.faService {
  color: #ff6c2c;
  font-size: 50px;
  text-align: center
}

.textCenter {
  text-align: center;
}

.serviceTitle{
  color: #293a4a;
  text-align:center;
  /* margin-left: 27px */
}

.description {
  font-size: 18px;
  color: #293a4a;
  font-family: 'Times New Roman', Times, serif
}

.aboutImage{
  width: 50%;
  height: 100%
}

.chooseUs {
  border: 1px solid #293a4a;
  background-color: #293a4a;
}

.processImageHeight {
  width : 100%;
  height : 100%
}

.processDiv {
  color: #ff6c2c;
  font-size: 30;
  display: flex;
  justify-content:center;
  flex-direction: row
}

.processIcon {
  color: #ff6c2c;
  font-size: 30;
  margin-top: 50
}

.myPic {
  width: 300;
  height: 150
}

.footerLocation {
  color: white;
  font-weight: 200
}

.footerLocations {
  color: white;
  font-weight: 500;
text-align: center
}

.spaceBottom{
  padding-bottom: 10px;
}

.spaceTop {
  margin-top: 50px
}

.boxStyle {
  border: 1px solid #888;
  padding: 10px;
  box-shadow:  10px 10px 5px #888
}

.placed{
  flex-direction: column;
  display: flex;
}

.placedStyle {
  height: 60px;
  width: 60px;
}

.topSpace {
  margin-Top: 20px
}

.bottomEmptySpace {
  padding-bottom: 50px
}

.servicesBox {
  border: 1px solid #ff6c2c;;
  padding: 10px;
  box-shadow: 5px 5px 5px 5px  #888
}

.textDesc {
  font-size: 18px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.borderStyle {
  transition: transform 1s; /* Animation */
  border-color: #ff6c2c;
  border-width: 1px;
}

.borderStyle:hover {
  transform: scale(1.1);
  background-color:  #fff;
}

.profile-pic {
  width:50%;
}