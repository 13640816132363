.interviewText {
    color :white;
    font-size:20px;
    font-weight: 300;
    font-family: 'Times New Roman', Times, serif
}

.mainDiv {
    border: '1px solid black';
    padding: 5px;
     background-color:#293a4a
}

.space {
    padding-bottom: 15px
}

.spaceBottom {
    padding-bottom: 50px
}

details {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: .5em .5em 0;
    background-color: #f6f7f8;
    margin-bottom: 20px;
}

summary::-webkit-details-marker {
    color: #fff;
    background-color: #ff6c2c;
}

summary {
    font-weight: bold;
    margin: -.5em -.5em 0;
    padding: .5em;
    cursor: pointer;
   
}

summary:before {
    content: "\002B"; /* the new icon */
    color: #696f7c;
    margin-right: 5px;
}

ul > li > a.hyperLink {
    color: #696f7c;
    font-weight: bold;
    font-size: 15px
}
ul > li > a :hover {
    background-color: #ff6c2c;
}