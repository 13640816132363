borderbox {
    border-right-width: 1px !important;
    border-left-width: 1px !important;
    border-left-color: #821616 !important;
    border-left-style: solid !important;
    border-right-color: #dedede !important;
    border-right-style: solid !important;
  }
.textDesign {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  margin-bottom: 5px;
  resize: vertical;
}

.buttonStyle {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.hrLine {
  border: '1px solid #293a4a'; 
  width: "10%";
  text-align:'center'
}
.faSize {
  color:'#ff6c2c';
  font-size: 50px
}

.hrRow {
   border: '1px solid #293a4a';
   width:"10%";
   text-align:'center'
  }